<template>
  <div class="item">
    <div class="flex space-between">
      <div class="item__personal flex">
        <h3 class="item__title">{{data.jobName}}</h3>
        <div class="item__personal--under">
          <span class="bold">[{{ data.educationLabel }}]</span>
          <span class="bold">[{{ data.jobAgeLabel }}]</span>
          <span class="item__treatment">{{ data.welfare }}</span>
        </div>
      </div>
      <!-- <span class="item__salary">{{ data.salaryMoneyMin | salary }}k-{{ data.salaryMoneyMax | salary }}k</span> -->
    </div>

    <!-- 移动端展示这个 -->
    <div class="item__personal--mobile-show">
      <span class="bold">[{{ data.educationLabel }}]</span>
      <span class="bold">[{{ data.jobAgeLabel }}]</span>
      <div>
        <span class="item__treatment">{{ data.welfare }}</span>
      </div>
    </div>


    <div class="item__require">
      <div class="item__require--title">任职要求：</div>
      <div class="item__require--desc" v-html="data.jobIntroduction">
      </div>
    </div>

    <!-- <div class="flex flex-end">
      <div class="item__contact text-center pointer" @click="onContactNow">
        立即联系
      </div>
    </div> -->

    <div class="contact-now-container">
      <div class="item__contact text-center pointer" @click="onContactNow">
        立即联系
      </div>
      <contact-now class="animated fadeInLeft" v-show="isShow"/>
    </div>
  </div>
</template>

<script>
import ContactNow from './ContactNow'
export default {
  name: 'RecruitmentItem',
  filters: {
    salary(money) {
      return money / 1000
    }
  },
  components: {
    ContactNow
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isShow:false
    }
  },
  methods: {
    onContactNow() {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(item) {
  padding: 24px 15px 22px 24px;
  color: #5C5D5D;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
  margin-bottom: 20px;
  position: relative;
  &:last-child {
    margin-bottom: 170px;
  }
  @include e(title) {
    font-size: 26px;
    color: $--color-black;
    @include media-mobile {
      font-size: 21px;
    }
  }

  @media (min-width: 1201px) {
    .item__personal--mobile-show {
      display: none;
    }
  }

  @media (max-width: 1200px) {
    // @include m(under) {
    //   display: none;
    // }
    .item__personal--mobile-show {
      display: block;
      margin-top: 10px;
      font-size: 14px;
      & span:nth-of-type(2) {
        padding-top: 9px;
        margin-left: 12px;
      }
      .item__treatment {
        margin-left: 0;
        display: inline-block;
      }
    }
  }

  @include e(personal) {
    font-size: 17px;
    @media (max-width: 1200px) {
      @include m(under) {
        display: none;
      }
    }
    & span:nth-of-type(1) {
      margin-left: 14px;
      padding-top: 9px;
    }
    & span:nth-of-type(2) {
      margin-left: 12px;
      padding-top: 9px;
    }
  }

  @include e(treatment) {
    font-size: 14px;
    padding-top: 12px;
  }

  @include e(salary) {
    color: #F5700B;
    font-size: 18px;
  }

  @include e(treatment) {
    margin-left: 12px;
  }

  @include e(require) {
    line-height: 24px;
    margin-top: 31px;
    @include m(title) {

    }

    @include m(desc) {

    }
  }

  .contact-now-container {
    position: absolute;
    right: 19px;
    bottom: 11px;
  }

  @include e(contact) {
    width: 102px;
    height: 40px;
    border-radius: $--border-radius-large;
    line-height: 40px;
    border: 1px solid #cacaca;
    color: #9e9e9e;
    transition: .2s;
    &:hover {
      background: $--color-primary;
      color: $--color-white;
      border: none;
    }

    @include media-mobile {
      position:static;
      align-self: center;
      margin-top: 9px;
    }
  }
}
</style>