<template>
  <div class="company-profile" :style="`background: url(${companyProfileBg})`">
    <ModuleHeader :headerData="{ title: '湖南科恩信息技术有限公司',desc: '一站式软件解决方案，助推智能商业升级'}"/>
    <div class="company-profile__intro">
        <div class="img-container">
          <img src="@/assets/images/company.png" alt="" class="company-bg"/>
          <img src="@/assets/images/about-us.png" alt="" class="about-us">
        </div>
        <p>
          科恩信息于2019年04月22日成立，是一家移动互联网应用研发公司，致力于从移动应用领域在市场拓展、品牌传播方面为企业提供产品、技术支持及售后服务
        </p>
        <p>
          专业从事软件开发，为各大企业提供互联网技术开发服务：如：APP开发、小程序开发、系统开发、智慧物联网开发等；目前拥有成熟的新零售、社区团购、智慧医保、智慧园区、线上教育等系统。其次，作为中联、筑友集团的核心合作伙伴，整合头部广告资源，为企业提供全链路数字化营销服务，助推企业转型升级。
        </p>
        <p>
          致力于为各地区客户提供本地化服务支持。公司拥有深厚的行业经验和领先的技术实力，在电子商务、新零售、内容付费、软硬件结合等多个领域形成了成熟的技术解决方案，成为推动企业互联网商业模式转型的有机力量。
        </p>

        <p>
          公司提供完整的软件定制开发服务，基于自主研发的框架定制符合企业需求的软件产品。
          服务团队包括项目经理、产品经理、交互设计师、技术总工、前端工程师、后端工程师、测试工程师等，各岗位人员紧密协作，严格把控各类项目，为客户提供一站式互联网行业解决方案。
        </p>

        <p>
          我们是追求品质和力求不断超越自己的团队，每一位成员也是亲密的伙伴，与公司一起成长与发展。我们尊重每次合作的机会与挑战，不断精进，力求完美。团队秉承专注、专业的设计服务思维,让客户通过设计发挥产品的价值，并发掘无限的可能。热爱设计并坚信设计的力量让我们不断前进...
        </p>
    </div>

    <More class="more" @click.native="toAboutUs"/>
  </div>
</template>

<script>
import ModuleHeader from './ModuleHeader.vue'
import More from '@/components/More.vue'

import companyProfileBg from '@/assets/images/company-profile-bg.png'
export default {
  name: 'CompanyProfile',
  components: {
    ModuleHeader,
    More
  },
  data() {
    return {
      companyProfileBg
    }
  },
  methods: {
    toAboutUs() {
      this.$router.push({
        name: 'about'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(company-profile) {
  padding: 52px 14% 20px;
  margin-top: 52px;
  background: #fff;
  // height: 100vh;
  .img-container {
      width: 50%;
      float: right;
      margin-left: 35px;
      margin-top: 10px;
      position: relative;
      @include media-mobile{
        width: 100%;
        float: none;
        margin: 0;
        position: relative;
        left: -31px;
        line-height: 32px;
        margin-bottom: 10px;
      }
    .company-bg {
      width: 100%;
    }

    .about-us {
          position: absolute;
          width: 42%;
          bottom: 23px;
          right: -18%;
    }
  }
  @include media-mobile {
    height: auto;
  }
  @include media-mobile{
    padding: 0 11vw;
  }


  
  @include e(intro) {
    text-indent: 2em;
    line-height: 32px;
    margin-top: 45px;
    @include media-mobile{
      line-height: 24px;
      margin-top: 24px;
    }
    @include media-min-width(1400px) {
      line-height: 38px;
    }
  }

  .more {
    margin-top: 110px;
    margin-bottom: 50px;
    @include media-mobile{
      margin-top: 50px;
      margin-bottom: 25px;
    }
  }
}
</style>