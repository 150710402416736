<template>
  <div class="excellent-case">
    <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="quickContactMobile" title="优秀案例 · 品牌故事"/>
    <!-- <div class="contact-container">
      <h2 class="title">快速开发，专业化定制</h2>
      <div class="spacial bold">定制属于你的个性需求</div>
      <div class="contact-now text-center bold">立即洽谈</div>
    </div> -->
    <!-- <div class="contact-now"></div> -->
    <div class="excellent-case__company">
      <!-- <FirstCompany class="hidden" :data="firstCompanyData"/> -->

      <div class="company-container flex flex-wrap space-between">
        <CompanyItem v-for="(item,index) in goodCaseData" :data="item" :key="index"/>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyItem from './components/CompanyItem'
import Banner from '@/components/Banner'
import bannerImgUrl from '@/assets/images/quick-contact.png'
import quickContactMobile from '@/assets/images/quick-contact-mobile.jpg'

import { goodCaseList } from '@/api/system'
export default {
  name: 'ExcellentCase',
  components: {
    CompanyItem,
    Banner
  },
  data() {
    return {
      bannerImgUrl,
      goodCaseData: [],
      firstCompanyData: {},
      quickContactMobile
    }
  },
  methods: {
    async getGoodCaseList() {
      let { rows } = await goodCaseList()
      this.goodCaseData = rows;
    }
  },
  created() {
    this.getGoodCaseList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(excellent-case) {
  position: relative;
  .banner {
  }
  // .contact-now {
  //   width: 191px;
  //   height: 50px;
  //   position: absolute;
  // }
  // .contact-container {
  //   position: absolute;
  //   top: 17%;
  //   left: 19%;
  //   color: $--color-white;
  //   .title {
  //     font-size: 40px;
  //     @include media-mobile {
  //       font-size: 18px;
  //     }
  //   }
  //   .spacial {
  //     margin-top: 22px;
  //     font-size: 18px;
  //     @include media-mobile {
  //        margin-top: 18px;
  //       font-size: 14px;
  //     }
  //   }
  //   .contact-now {
  //     width: 191px;
  //     height: 50px;
  //     line-height: 50px;
  //     border: 1px solid $--color-white;
  //     border-radius: 7px;
  //     font-size: 17px;
  //     margin-top: 45px;
  //     @include media-mobile {
  //       width: 141px;
  //       height: 30px;
  //       margin-top: 30px;
  //       font-size: 14px;
  //       line-height: 30px;
  //     }
  //   }
  // }
  @include e(company) {
    padding: 0 7vw;
    .hidden {
      @include media-mobile {
        display: none;
      }
    }
    @include media-mobile {
      padding: 80px;
    }
    @include media-sm-mobile {
      padding: 20px 13px;
    }
    // @include media-between() {
    //   padding: 80px;
    // }
  }

  .company-container {
    margin-top: 40px;
  }
}
</style>