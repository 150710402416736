<template>
  <div class="module-header">
    <div class="text-center">
        <h1 class="module-header__name">{{headerData.title}}</h1>
        <span class="module-header__desc">{{headerData.desc}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleHeader',
  props: {
    headerData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(module-header) {
  @include e(name) {
    font-size: 34px;
    color: #5e5e5e;
    margin-bottom: 15px;
    @include media-mobile{
      font-size: 23px;
    }
  }
  @include e(desc) {
    color: #c7c7c7;
    font-size: 22px;
    @include media-mobile{
      font-size: 16px;
    }
  }
}
</style>