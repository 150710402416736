<template>
  <div class="home-page">
    <!-- <img src="@/assets/images/banner.png" alt="" class="banner__img"> -->
    <div :class="['first-module',isFirstScale ? 'is-scale' : '']">
      <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="bannerMobile"/>
      <div class="features flex flex-center">
          <FeatureItem 
              v-for="(item,index) in featureData" 
              :key="index"
              @click.native="handleFeature(index)"
              :index="index"
              ref="FeatureItem"
              :data="item"
          />
      </div>
    </div>

    <div :class="['first-module',isSecondScale ? 'is-scale' : '']">
      <CompanyProfile />
    </div>
    <div :class="['first-module',isThirdScale ? 'is-scale' : '']">
      <Solution />
    </div>
    <div :class="['first-module',isFourScale ? 'is-scale' : '']">
      <CommonChoice />
    </div>
    <div :class="['first-module',isFifthScale ? 'is-scale' : '']">
      <Recruitment />
    </div>

  </div>
</template>

<script>
import FeatureItem from './components/FeatureItem.vue'
import CompanyProfile from './components/CompanyProfile.vue'
import More from '@/components/More.vue'
import CommonChoice from './components/CommonChoice.vue'
import Recruitment from './components/Recruitment.vue'
import Solution from './components/Solution.vue'
import Banner from '@/components/Banner'

import miniProgramImg from '@/assets/images/mini-program-icon.png'
import computerImg from '@/assets/images/computer.png'
import wulianImg from '@/assets/images/wulian.png'
import bannerImgUrl from '@/assets/images/banner.png'
import bannerMobile from '@/assets/images/banner-mobile.jpg'

import { getScrollOffset } from '@/utils/index.js'
export default {
  components: {
    FeatureItem,
    CompanyProfile,
    More,
    CommonChoice,
    Recruitment,
    Solution,
    Banner
  },
  data() {
    return {
      featureData:[
        {
          title: '网站开发',
          types: ['品牌型网站','营销型网站','产品型网站','网上商城服务'],
          icon: computerImg,
        },
        {
          title: '小程序开发',
          types: ['官网小程序','直播小程序','商城小程序','餐饮小程序'],
          icon: miniProgramImg
        },
        {
          title: '智慧园区搭建',
          types: ['停车管理','访客系统','门禁管理','人员数据'],
          icon: computerImg
        },
        {
          title: '物联网',
          types: ['物联网卡','解决方案','芯片模组','物联网卡平台'],
          icon: wulianImg
        },
      ],
      bannerImgUrl,
      bannerMobile,
      isFirstScale: false,
      isSecondScale: false,
      isThirdScale: true,
      isFourScale: false,
      isFifthScale: false
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.FeatureItem[0].isActive = true
    })
  },
  methods: {
    handleFeature(index) {
      // 取消其它组件选中状态
      this.cancleOther()
      this.$refs.FeatureItem[index].isActive = true
    },
    cancleOther() {
      this.$refs.FeatureItem.forEach(component => {
        component.isActive = false
      })
    },
    onScroll() {
      let scrollOffset = getScrollOffset()

      // 后面做优化
      if(scrollOffset.top > 300) {
        this.isFirstScale = true
      } else {
        this.isFirstScale = false
      }

      if(scrollOffset.top > 1200) {
        this.isSecondScale = true
        this.isThirdScale = false
      } else {
        this.isSecondScale = false
        this.isThirdScale = true
      }

      if(scrollOffset.top > 2207) {
        this.isThirdScale = true
        this.isFourScale = false
      } else {
        this.isThirdScale = false
        this.isFourScale = true
      }

      if(scrollOffset.top > 3400) {
        this.isFourScale = true
        this.isFifthScale = false
      } else {
        this.isFourScale = false
        this.isFifthScale = true
      }



    }
  },
  mounted() {
      window.addEventListener("scroll", this.onScroll); //监听页面滚动
  },
  destroyed() {
      window.removeEventListener("scroll", this.onScroll); //销毁滚动事件
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(features) {
  border-radius: $--border-radius-large;
  position: relative;
  top: -10px;
  background: $--color-white;
  // padding: 0 2vw;
  // padding: 0 140px;
  // @media screen and (min-width:1200px){
  //   padding: 0 80px;
  // }
  @include media-computer {
    padding: 0 80px;
  }

}
.home-page {
  // background: #e7e7e7;
  .first-module {
    transform: scale(1);
    transition: .3s;
  }
  .is-scale {
    transform: scale(0.9) !important;
  }
}
</style>