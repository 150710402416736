<template>
  <div class="module-header">
    <div class="text-center">
        <h2 class="module-header__name">后台管理系统</h2>
        <span class="module-header__desc">PC_Link 后台管理子系统</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseModuleHeader',
  props: {
    headerData: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(module-header) {
  margin: 138px 0 70px 0;
  @include e(name) {
    font-size: 27px;
    margin-bottom: 13px;
    // @include media-mobile{
    //   font-size: 24px;
    // }
  }
  @include e(desc) {
    color: #5e5e5e;
    font-size: 20px;
    // letter-spacing: 1px;
  }
}
</style>