import axios from 'axios'

const httpConfig = {
    baseURL: "https://ndke.nd-cohen.com/",
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json'
    }
}
class Http {
    constructor(config) {
        this.instance = this.create(config)

        this.beforeRequest()
        this.afterRequest()
    }

    create (config) {
        return axios.create(config)
    }

    // 请求拦截器
    beforeRequest() {
        this.instance.interceptors.request.use(function (config) {
            // 在发送请求之前做些什么
            return config;
        }, function (error) {
            // 对请求错误做些什么
            return Promise.reject(error);
        });
    }

    // 相应拦截器
    afterRequest() {
        this.instance.interceptors.response.use(function (response) {
            // 对响应数据做点什么
            return response;
        }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
        });
    }

    request(config) {
        return new Promise((resolve,reject) => {
            this.instance
            .request(config)
                .then(res => {
                    resolve(res.data)
                })
                .catch(erro => {
                    // reject(erro)
                })
        })
    }

    get(url,params = {},options = {}) {
        return this.request({
            url,
            params,
            options,
            method: "get"
        })
    }

    post(url,data = {},options = {}) {
        return this.request({
            url,
            data,
            options,
            method: "post"
        })
    }

    delete (url, data = {}) {
        return this.request({
            url,
            method: 'delete',
            data
        })
    }

    put (url, data = {}, type = 'body') {
        let key = type === 'query' ? 'params' : 'data'
        return this.request({
            url,
            method: 'put',
            [key]: data
        })
    }
}

const http = new Http(httpConfig)

export default http
