<template>
  <div class="common-choice" :style="`background: url(${commonChoiceBg})`">
    <ModuleHeader :headerData="{ title: '各行业标杆企业的共同选择',desc: '软件开发 找科恩'}"/>
    <div class="flex flex-wrap" style="margin-top: 60px">
      <Enterprise v-for="(item,index) in cooperativeList" :key="index" :data="item"/>
    </div>

    <!-- <div class="text-center">
      <More class="more"/>
    </div> -->
  </div>
</template>

<script>
import ModuleHeader from './ModuleHeader.vue'
import Enterprise from './Enterprise.vue'
import commonChoiceBg from '@/assets/images/common-choice-bg.png'
import More from '@/components/More.vue'

import { cooperativeList } from '@/api/system'
export default {
  nmae: 'CommonChoice',
  components: {
    ModuleHeader,
    Enterprise,
    More
  },
  data() {
    return {
      cooperativeList: [],
      commonChoiceBg
    }
  },
  methods: {
    async getCooperativeList() {
      let { rows } = await cooperativeList()
      this.cooperativeList = rows
    }
  },
  created() {
    this.getCooperativeList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(common-choice) {
  margin-top: 61px;
  padding: 52px 14% 0;
  height: 100vh;
  position: relative;
  @include media-mobile {
    height: auto;
  }
  @include media-mobile{
    padding: 0 9vw;
  }
  // @include media-min-width(1500px) {
  //   padding: 0 200px;
  // }

  .more {
    position: absolute;
    bottom: 38px;
    left: 50%;
    @include media-mobile{
      position: static;
      display: inline-block;
      margin-top: 38px;
    }
  }
}

@media (min-width: 1500px) {
  .common-choice {
    padding: 0 200px !important;
  }
}
</style>