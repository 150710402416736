<template>
  <div class="contact">
    <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="quickContactMobile" title="联系我们 · 一起加油干！"/>
    <div :style="`background: url(${contactBg});padding: 44px 0 178px 0;`">
      <ModuleHeader :headerData="{ title: '联系我们',desc: 'CONTACT US'}" style="margin-top: 44px"/>
      <div class="flex company-container">
        <div class="flex flex-center allmap-container">
          <div id="allmap"></div>
        </div>
        <div class="contact-company-info flex-col flex-center align-items">
          <h2 class="contact__comnany-name text-center">
            湖南科恩信息技术有限公司
          </h2>

          <div class="contact__address">
            <p class="text-center">长沙高新开发区麓龙路199号 </p>
            <p class="text-center">麓谷商务中心A栋602</p>
          </div>

          <div class="text-center">
            <img src="@/assets/images/qr-code.png" alt="" class="contact__qrcode">
          </div>

          <div class="contact-now-container text-center pointer" @click="onContactNow">
            <div class="contact__immediate">立即联系</div>
            <contact-now class="animated fadeInLeft" v-show="isShow"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleHeader from '@/views/HomePage/components/ModuleHeader'
import bannerImgUrl from '@/assets/images/quick-contact.png'
import contactBg from '@/assets/images/contact-bg.png'
import Banner from '@/components/Banner'
import quickContactMobile from '@/assets/images/quick-contact-mobile.jpg'

import ContactNow from './components/ContactNow'
export default {
  name: 'Contact',
  components: {
    ModuleHeader,
    Banner,
    ContactNow
  },
  data() {
    return {
      bannerImgUrl,
      quickContactMobile,
      isShow: false,
      contactBg
    }
  },
  mounted() {
      this.baiduMap()
  },
  methods: {
    baiduMap() {
      // 百度地图API功能
      var map = new BMap.Map("allmap");    // 创建Map实例
      map.centerAndZoom(new BMap.Point(112.900883, 28.219443), 14);  // 初始化地图,设置中心点坐标和地图级别

      // 添加标点
      var point = new BMap.Point(112.900883, 28.219443);   
      var marker = new BMap.Marker(point);        // 创建标注   
      map.addOverlay(marker);                     // 将标注添加到地图中

      
      //添加地图类型控件
      map.addControl(new BMap.MapTypeControl({
        mapTypes:[
                BMAP_NORMAL_MAP,
                BMAP_HYBRID_MAP
            ]}));	  
      map.setCurrentCity("北京");          // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true);     //开启鼠标滚轮缩放
    },
    onContactNow() {
       this.isShow = !this.isShow
    }
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(contact) {
  .contact-company-info {
    flex: 1;
    background: $--color-white;
    padding: 8% 2% 4% 0;
    @include media-computer {
      margin-left: 14px;
    }
  }

  .company-container {
    padding: 0 6vw;
    margin-top: 60px;
    @include media-mobile {
      display: block !important;
    }
  }

  @include e(comnany-name) {
    color: #5E5E5E;
  }

  @include e(address) {
    margin: 28px 0 25px 0;
    color: #5E5E5E;
    line-height: 24px;
  }


  @include e(qrcode) {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  @include e(immediate) {
    display: inline-block;
    width: 112px;
    height: 40px;
    line-height: 40px;
    background: $--color-primary;
    color: $--color-white;
    border-radius: 20px;
  }

  .contact-now-container {
    position: relative;
  }
}

.allmap-container {
    width: 64%;
    @include media-mobile {
        width: 100%;
    }
    #allmap {
      width: 100%;
      overflow: hidden;
      flex: 2;
      display: inline-block;
      @include media-mobile {
          width: 64vw;
          height: 52vw;
          margin-bottom: 40px;
      }
    }
}
</style>