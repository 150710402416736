<template>
    <div class="solution" :style="`background: url(${solutionBg})`">
        <ModuleHeader :headerData="{ title: '针对不同行业的大数据化解决方案',desc: '一站式解决'}" style="margin-bottom: 52px"/>


        <ul class="solution-list">
            <li :class="['solution-list__item',index == currentIndex ? 'on' : '']" style="height: 637px;" @mouseover="onSwich(index)" v-for="(item,index) in swiperData" :key="index">
                <b class="solution-list__img" 
                    :style="{background: `url(${item.img}) right center no-repeat`,backgroundSize: 'cover',backgroundPosition: index !== currentIndex ? '-608px 0' : 'right center'}"
                >
                </b>
                <b :class="['b2',index == currentIndex ? 'b2-on' : '']"></b>
                <div :class="['solution__description',index == currentIndex ? 'on-desc' : '']"
                     >
                    <h2 class="solution-list__title">
                        {{item.title}}
                    </h2>
                    <span class="sp2" v-html="item.text"></span>
                </div>

                <img v-show="index !== currentIndex" src="@/assets/images/right-arrow.png" alt="" class="right-arrow">
            </li>
        </ul>

        <More class="more" @click.native="toExcellentCase"/>
    </div>
</template>

<script>
import img1 from '@/assets/images/01.png'
import img2 from '@/assets/images/02.png'
import img3 from '@/assets/images/03.png'
import img4 from '@/assets/images/04.png'
import img5 from '@/assets/images/05.png'
import swiperDes from '@/assets/images/swiper-des.png'
import solutionBg from '@/assets/images/solution-bg.png'

import ModuleHeader from './ModuleHeader.vue'
import More from '@/components/More.vue'
export default {
    name: '',
    data() {
        return {
            currentIndex: 0,
            swiperDes,
            swiperData: [
                {
                    title: '机 械 制 造',
                    img: img1,
                    text: 'M<br>A<br>N<br>U<br>F<br>A<br>C<br>T<br>U<br>R<br>I<br>N<br>G'
                },
                {
                    title: '拓 维 信 息',
                    img: img2,
                    text: 'N<br>F<br>O<br>R<br>M<br>A<br>T<br>I<br>O<br>N<br>'
                },
                {
                    title: '筑 友 集 团',
                    img: img3,
                    text: 'Z<br>H<br>U<br>Y<br>O<br>U<br> <br>G<br>R<br>O<br>U<br>P<br>'
                },
                {
                    title: '娄 底 移 动',
                    img: img4,
                    text: 'C<br>H<br>I<br>N<br>A<br> <br>M<br>O<br>B<br>I<br>L<br>E'
                },
                {
                    title: '爱 龙 教 育',
                    img: img5,
                    text: 'E<br>D<br>U<br>C<br>A<br>T<br>I<br>O<br>N'
                }

            ],
            solutionBg
        }
    },
    components: {
        ModuleHeader,
        More
    },
    methods: {
        onSwich(index) {
            this.currentIndex = index
        },
        toExcellentCase() {
            this.$router.push({
                name: 'excellentCase'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
.solution {
    overflow: hidden;
    width: 100%;
    margin-top: 60px;
    position: relative;
    padding-top: 52px;
    height: 100vh;
    @include media-mobile {
        display: none;
    }

    .more {
        position: absolute;
        bottom: 38px;
        left: 50%;
    }
}
@include b(solution-list) {
    position: relative;
    .on {
        width: 60% !important;
        position: relative;
        z-index: 990;
    }
    @include e(item) {
        width: 10%;
        overflow: hidden;
        float: left;
        // background-color: #000;
        position: relative;
        // &:hover.on {
        //     width: 60%;
        //     position: relative;
        //     z-index: 990;
        //     box-shadow: 0px 0px 21px rgba(0,0,0,0.85);
        // }
        // &:hover .b2 {
        //     opacity: 0;
        // }
        .b2 {
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: rgba(0,0,0,0.6);
            transition: .3s linear;
            -webkit-transition: .3s linear;
        }

        .b2-on {
            opacity: 0;
        }

        .right-arrow {
            position: absolute;
            bottom: 16%;
            left: 34%;
        }
    }

    @include e(img) {
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        position: absolute;
        left: 0px;
        top: 0px;
    }


    .solution__description {
        color: $--color-white;
        width: 143px;
        height: 488px;
        padding: 43px 0px;
        overflow: hidden;
        position: absolute;
        left: 30%;
        margin-left: -50px;
        top: 12%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        transition: .3s linear;
        -webkit-transition: .3s linear;
        border-top-right-radius: 66px;
    }

    .on-desc {
        left: 90px;
        background: url('./swiper-des.png') center no-repeat;
        background-position: -22px -65px;
    }

    @include e(title) {
        padding: 10px 59px;
        font-size: 28px;
        line-height: 32px;
    }
    .sp2 {
        width: 12px;
        display: inline-block;
        overflow: hidden;
        position: absolute;
        left: 67px;
        top: 191px;
        line-height: 12px;
        text-align: center;
        font-family: Arial;
        font-size: 12px;
        text-transform: uppercase;
        word-break: break-all;
    }

    .text {
        // padding: 10px 59px;
        // width: 10px;
    }
    
}
</style>