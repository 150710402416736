<template>
  <div class="recruitment" :style="`background: url(${commonChoiceBg})`">
    <ModuleHeader :headerData="{ title: '广纳贤士',desc: '招揽有识之士共谋发展'}"/>
    <div class="flex flex-wrap" style="margin-top: 48px">
      <!-- 比例 -->
      <RecruitmentItem 
          v-for="(item,index) in recruitData" 
          :key="index" 
          :data="item"
          :radio="index === 0 ? 2 : 1"/>

      <More class="more" @click.native="toRecruitment"/>
    </div>
  </div>
</template>

<script>
import ModuleHeader from './ModuleHeader.vue'
import RecruitmentItem from './RecruitmentItem.vue'
import More from '@/components/More.vue'
import { recruitmentList } from '@/api/system'

import commonChoiceBg from '@/assets/images/recrument-bg.png'
export default {
  name: 'Recruitment',
  components: {
    ModuleHeader,
    RecruitmentItem,
    More
  },
  data() {
    return {
      recruitData: [],
      commonChoiceBg
    }
  },
  methods: {
    async getWebList() {
      let { rows } = await recruitmentList()
      this.recruitData = rows
    },
    toRecruitment() {
      this.$router.push({
          name: 'recruitment'
      })
    }
  },
  created() {
    this.getWebList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(recruitment) {
  margin-top: 61px;
  padding: 52px 140px 0;
  height: 100vh;
  margin-bottom: 200px;
  @include media-mobile {
    height: auto;
    margin-bottom: 100px;
  }
  position: relative;
  @include media-between(1200px,1500px) {
    padding: 52px 70px;
  }

  @include media-mobile {
    padding: 52px 35px 0;
  }

  @include media-sm-mobile{
    padding: 52px 8px;
  }

  .more {
      position: absolute;
      bottom: 3%;
      left: 50%;
      @include media-mobile {
        left: 42%;
        bottom: 0;
      }
  }
}
</style>