<template>
  <div class="banner">
      <div class="contact-now pointer" @click="toContact"></div>
      <img :src="imgUrl" alt="" class="banner__img">
      <div class="bannerZ-container flex items-middle" v-if="title" >
        <img src="@/assets/images/bannerZ.png" alt="" style="width: 76vw">
        <h2 class="banner__title">{{ title }}</h2>
      </div>
  </div>
</template>

<script>
import { getWindowWidth } from '@/utils/index'
export default {
  name: 'Banner',
  props: {
    bannerImgUrl: String,
    bannerMobile: String,
    title: String
  },
  data() {
    return {
    }
  },
  created() {
    getWindowWidth() < 1056 ? this.imgUrl = this.bannerMobile : this.imgUrl = this.bannerImgUrl
  },
  methods: {
    toContact() {
      this.$router.push({
        name: 'contact'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(banner) {
  position: relative;
  .contact-now {
    width: 17%;
    height: 12%;
    position: absolute;
    left: 13%;
    top: 54%;
  }
  .bannerZ-container {
    position: absolute;
    bottom: 2px;
  }
  @include e(img) {
    
  }

  @include e(title) {
    position: absolute;
    bottom: 37%;
    left: 42px;
    color: $--color-white;
    @include media-mobile {
      font-size: 16px;
    }
  }

  .bannerZ-container {
    @include media-mobile {
      display: none;
    }
  }

}

</style>