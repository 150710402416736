<template>
  <div :class="['item',radio === 1 ? 'after-item' : 'first-item']" >
    <div class="flex space-between">
      <h3 class="item__title">{{ data.jobName }}</h3>
      <div class="item__personal bold" v-if="radio == 2">
        <span>[{{ data.educationLabel }}]</span>
        <span>[{{data.jobAgeLabel}}]</span>
      </div>
      <!-- <span class="item__salary">{{ data.salaryMoneyMin | salary }}k-{{ data.salaryMoneyMax | salary }}k</span> -->
    </div>
    
    <div class="item__treatment">
      <template v-if="radio !== 2">
        <span class="bold">{{ data.educationLabel }}</span>
        <span class="bold">[{{data.jobAgeLabel}}]</span>
      </template>
      {{ data.welfare }}
    </div>

    <div class="item__require">
      <div class="item__require--title">任职要求：</div>
      <div class="item__require--desc" v-html="data.jobIntroduction">
        <!-- <p>
          1、具有扎实JAVA基础，熟练应用常用设计模式，有良好编程习惯；全日制大专以上学历。 
        </p>
        <p>
          2、熟练使用一种或多种主流数据库系统、如mysql、postgresql、oracle等，至少掌握一种NOSQL的使用
        </p> -->
      </div>
    </div>

    <div class="contact-now-container">
      <div class="item__contact text-center pointer" @click="onContactNow">
        立即联系
      </div>
      <contact-now class="animated fadeInLeft" v-show="isShow"/>
    </div>
  </div>
</template>

<script>
import ContactNow from './ContactNow'
export default {
  name: 'RecruitmentItem',
  filters: {
    salary(money) {
      return money / 1000
    }
  },
  components: {
    ContactNow
  },
  props: {
    radio: Number,
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    onContactNow() {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
.after-item {
  width: 24%;
  @include media-mobile {
    width: 48%;
  }
  @include media-sm-mobile {
    width: 100%;
  }
}

.first-item {
  width: 48%;
  @include media-mobile {
    width: 100%;
  }
}

@include b(item) {
  height: 285px;
  padding: 32px 26px 30px 19px;
  color: #5C5D5D;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.16);
  border-top-right-radius: 40px;
  margin-left: 10px;
  margin-top: 10px;
  position: relative;
  background: $--color-white;
  @include media-mobile {
    height: 296px;
  }
  @include media-sm-mobile {
    margin-left: 0;
  }
  @include e(title) {
    font-size: 23px;
    color: $--color-black;
    @include media-mobile {
      font-size: 19px;
    }
  }

  @include e(personal) {
    font-size: 16px;
    @include media-mobile {
      font-size: 14px;
    }
    & span:nth-child(2) {
      margin-left: 29px;
    }
  }

  @include e(salary) {
    color: #F5700B;
    font-size: 18px;
    @include media-sm-mobile {
      font-size: 14px;
    }
  }

  @include e(treatment) {
    margin: 15px 0 23px 0;
    // @include utils-ellipsis;
    width: 90%;
    @include utils-ellipsis;
    span {
      font-size: 16px;
      @include media-mobile {
        font-size: 14px;
      }
    }
    & span:nth-child(2) {
      margin-left: 8px;
    }
  }

  @include e(require) {
    line-height: 24px;
    @include m(title) {

    }

    @include m(desc) {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      @include media-sm-mobile {
        -webkit-line-clamp: 2;
      }
    }
  }

  .contact-now-container {
    position: absolute;
    right: 0;
    bottom: 29px;
  }

  @include e(contact) {
    width: 102px;
    height: 40px;
    background: $--color-primary;
    border-radius: $--border-radius-large;
    color: $--color-white;
    line-height: 40px;
    margin-top: 30px;
    margin-right: 26px;
    @include utils-user-select(none)
    // position: absolute;
    // bottom: 6%;
  }
}
</style>