<template>
  <div class="enterprise animateBounce pointer">
     <div class="img-con text-center">
       <img :src="data.logo || data.bgImage" alt="" class="enterprise__img">
     </div>
     <div class="enterprise__name text-center">{{ data.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'Enterprise',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";

@include b(enterprise) {
  width: 16%;
  margin-left: 4%;
  margin-bottom: 3%;
  // @include media-mobile {
  //   width: 20%;
  //   &:nth-child(4n + 1 ) {
  //     margin-left: 0;
  //   }
  // }

  

  &:hover.animateBounce  {
    animation-name: myBounce;
    animation-duration: .5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-direction: alternate;
  }

  @keyframes myBounce {
    100% {
      transform: scale(1.15);
    }
  }
  @include media-computer {
    // width: 20%;
    &:nth-child(5n + 1 ) {
      margin-left: 0;
    }
  }

  @include e(img) {
    // width: 88%;
    max-width: 220px;
    width: 100%;
    // @include media-mobile {
    //   width: 110%;
    // }
  }

  @include e(name) {
    color: #6e6e6e;
    margin-top: 10px;
    font-size: 17px;
    @include media-mobile {
      font-size: 12px;
    }
  }
}
</style>