import http from './http'

let base = '/system'

// 招贤纳士列表
export const recruitmentList = data => http.post(`${base}/recruitment/webList`,data)

// 优秀案例列表
export const goodCaseList = data => http.post(`${base}/goodCase/webList`,data)

// 合作企业列表
export const cooperativeList = data => http.post(`${base}/cooperative/webList`,data)