<template>
  <div class="about-item text-center">
    <h2 class="about-item__title">
      {{data.title}}
    </h2>
      <img :src="data.img" alt="" class="about-item__img">
  </div>
</template>

<script>
export default {
  name: 'AboutItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(about-item) {
  &:last-child {
    margin-bottom: 40px;
  }
  @include e(title) {
    font-size: 26px;
    margin: 91px 0 42px 0;
    @include media-mobile {
      font-size: 17px;
    }
  }

  @include e(img) {
    font-size: 28px;
    width: 100%;
    border: 1px dashed #969798;
  }
}
</style>