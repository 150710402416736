<template>
  <div class="case-detail">
    <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="quickContactMobile" :title="cooperativeName"/>
    <div v-html="caseIntroduction">

    </div>
    <!-- <div class="excellent-case__company">
      <FirstCompany />

      <div class="company-container flex flex-wrap space-between">
        <CompanyItem v-for="(item,index) in 7" :key="index"/>
      </div>
    </div>
    <ModuleHeader :headerData="{ title: '筑友集团',desc: 'ZHUYOU GROUP'}" style="margin-bottom: 48px;margin-top: 100px"/>
    <div class="case-detail__intro">
      <p>筑友集团定位为智慧建筑整体解决方案服务商，旨在以EMPC为核心产品，打造涵盖研发、设计、PC构件、装备、施工、装饰、园林、智能家居等业务的全生态链优势企业</p>
      <p>集团始终坚持“科技领先”的发展战略，拥有行业全球领先的六大核心技术体系，掌握了智慧建筑领域BIM、物联网、大数据、人工智能等核心技术。集团拥有各类专利1600项，专利数量连续6年稳居国内行业第一，设有博士后科研工作站、省级工程研究中心等科研平台，获评为智能制造示范企业、国家装配式建筑产业基地等。</p>
      <p>筑友集团已在全国22省、45城布局智能化数字工厂，全球首创的EMPC业务模式得到社会和客户广泛认可，目前已服务全国项目600万㎡，工程施工面积和智能化生产线数量位居行业前列。
      </p>
    </div>

    <CaseModuleHeader />

    <div class="flex flex-center">
      <img src="@/assets/images/login.jpg" alt="" class="case-detail__company-img">
    </div> -->
  </div>
</template>

<script>
import ModuleHeader from '@/views/HomePage/components/ModuleHeader'
import CaseModuleHeader from './CaseModuleHeader'
import Banner from '@/components/Banner'
import bannerImgUrl from '@/assets/images/quick-contact.png'
import quickContactMobile from '@/assets/images/quick-contact-mobile.jpg'
export default {
  name: 'CaseDetail',
  components: {
    ModuleHeader,
    Banner,
    CaseModuleHeader
  },
  data() {
    return {
      bannerImgUrl,
      caseIntroduction: this.$route.params.caseIntroduction,
      quickContactMobile,
      cooperativeName:  this.$route.params.cooperativeName
    }
  },
  watch: {
    '$route' (to, from) {
      let {caseIntroduction, cooperativeName} = this.$route.params;
      this.caseIntroduction = caseIntroduction;
      this.cooperativeName = cooperativeName;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(case-detail) {
  margin-bottom: 370px;
  @include media-mobile {
    margin-bottom: 150px;
  }
  // @include e(intro) {
  //   padding: 0 15vw;
  //   line-height: 40px;
  //   text-indent: 2em;
  //   font-size: 16px;
  // }

  // @include e(company-img) {
  //   width: 50%;
  //   margin-bottom: 50px;
  // }
}

/deep/ p:not(:first-child) {
  margin-top: 61px;
}
/deep/ img {
    width: 100%!important;
}
</style>