<template>
  <div class="feature-item pointer" 
      :style="{'background-image': isActive ? `url(${featureImg})` : '',
      color: isActive ? '#fff' : ''}" 
  >
    <div class="feature-titlecon">
      <img class="feature-item__logo" :src="data.icon" alt="小程序开发">
      <h2 class="feature-item__title" :style="{ color: isActive ? '#fff' : '' }">{{ data.title }}</h2>
    </div>

    <div class="feature-item__detail flex flex-wrap">
      <!-- 网上商城服务字体太长了 margin-right 为0 -->
      <div :class="['feature-item__detail-desc',item === '网上商城服务' ? 'margin-right0' : '']" v-for="(item,index) in data.types" :key="index">
        <VIcon name="icon-arrow-right" class="arrow" />
        <span>{{ item }}</span>
      </div>
    </div>

    <div class="feature-item__divider" v-show="!isActive"></div>
  </div>
</template>

<script>
import featureImg from '@/assets/images/feature.png'
export default {
  name: "FeatureItem",
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    index: Number
  },
  data() {
    return {
      // 背景图片
      featureImg,
      // 选中状态
      isActive: false
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";

@include b(feature-item) {
  padding: 0 10px 0 20px;
  @include media-sm-mobile {
    padding: 0 5px 0 5px;;
  }
  color: $--color-gray;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.16);
  // title容器
  .feature-titlecon {
    margin: 15px 0;
    display: flex;
    align-items: center;
    color: $--color-black;
    @include media-min-width(1500px) {
      margin-bottom: 20px;
    }
  }

  @include e(logo) {
    width: 35px;
    @include media-sm-mobile{
      width: 18px;
    }
    @include media-mobile {
      width: 25px;
    }
    @include media-min-width(1500px) {
      width: 50px;
    }
  }
  @include e(title) {
    font-size: 21px;
    margin-left: 20px;
    @include media-sm-mobile{
      font-size: 12px;
    }
    @include media-mobile {
      margin-left: 5px;
    }
    @include media-min-width(1500px) {
      font-size: 24px;
    }
  }

  @include e(detail) {
    margin-left: 55px;
    margin-bottom: 10px;
    font-size: 15px;
    max-width: 326px;
    @include media-min-width(1500px) {
      margin-left: 67px;
    }
    @include media-mobile {
      display: none;
    }
  }

  @include e(detail-desc) {
    margin-right: 10px;
    margin-bottom: 10px;
    @include media-min-width(1500px) {
      margin-right: 36px;
      margin-bottom: 15px;
    }
  }

  .margin-right0 {
    margin-right: 0;
  }
}
</style>