<template>
  <div class="about">
    <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="quickContactMobile" title="关于我们 · 我们的优势"/>
    <div class="about-container">
      <div class="flex nd-container">
        <div class="module-header">
          <h1 class="module-header__name">公司介绍</h1>
          <span class="module-header__desc">COMPANY INTRODUCTION</span>
        </div>

        <div class="about__nd-company">
          <h2 class="about__nd-title">
            科恩信息，一站式软件服务提供商
          </h2>

          <div class="about__nd-intro">
            科恩信息于2019年04月22日成立，是一家移动互联网应用研发公司，致力于从移动应用领域在市场拓展、品牌传播方面为企业提供产品、技术支持及售后服务， 专业从事软件开发，为各大企业提供互联网技术开发服务：如：APP开发、小程序开发、系统开发、智慧物联网开发等；目前拥有成熟的新零售、社区团购、智慧医保、智慧园区、线上教育等系统。其次，作为中联、筑友集团的核心合作伙伴，整合头部广告资源，为企业提供全链路数字化营销服务，助推企业转型升级。 致力于为各地区客户提供本地化服务支持。公司拥有深厚的行业经验和领先的技术实力，在电子商务、新零售、内容付费、软硬件结合等多个领域形成了成熟的技术解决方案，成为推动企业互联网商业模式转型的有机力量。 公司提供完整的软件定制开发服务，基于自主研发的框架定制符合企业需求的软件产品。服务团队包括项目经理、产品经理、交互设计师、技术总工、前端工程师、后端工程师、测试工程师等，各岗位人员紧密协作，严格把控各类项目，为客户提供一站式互联网行业解决方案。 我们是追求品质和力求不断超越自己的团队，每一位成员也是亲密的伙伴，与公司一起成长与发展。我们尊重每次合作的机会与挑战，不断精进，力求完美。团队秉承专注、专业的设计服务思维,让客户通过设计发挥产品的价值，并发掘无限的可能。热爱设计并坚信设计的力量让我们不断前进...
          </div>
        </div>
      </div>

      <div class="about-internet-container">
        <h2 class="about__internet-title text-center">智慧互联·物联网</h2>
        <div class="bottom-container flex space-between">
          <div class="left-container">
            <h3 class="about__smart-park">智慧园区</h3>
            <p class="about__internet-intro">
              园区内设施及人员结构复杂，很难形成统一有效的管理。搭建智慧园区管理系统，可以解决信息孤岛，打通园区内的各种应用系统；同时实现对租赁、水电缴费的集中管理，对园区水电、路灯、消防、监控、停车、门禁、等进行统一监管，令数据共享与流转，极大提高工作人员的工作效率，真正实现数字化、智慧化的园区。
            </p>
          </div>

          <img src="@/assets/images/about-internet.png" alt="" class="about__internet-img">
        </div>


      </div>

      <AboutItem v-for="(item,index) in aboutData" :key="index" :data="item"/>
    </div>


  </div>
</template>

<script>
import Banner from '@/components/Banner'
import AboutItem from './components/AboutItem'
import bannerImgUrl from '@/assets/images/quick-contact.png'
import parkFinishing from '@/assets/images/park-finishing.png'
import systemArchiConstruct from '@/assets/images/system-archi-construct.png'
import appliLayerClient from '@/assets/images/appli-layer-client.png'
import supportServiceTeam from '@/assets/images/support-service-team.png'
import hardwareDisplay from '@/assets/images/ahardware-display.png'
import quickContactMobile from '@/assets/images/quick-contact-mobile.jpg'
export default {
  components: {
    Banner,
    AboutItem
  },
  data() {
    return {
      bannerImgUrl,
      quickContactMobile,
      aboutData: [
        {
          title: '园区整理规划',
          img: parkFinishing
        },
        {
          title: '系统架构搭建',
          img: systemArchiConstruct
        },
        {
          title: '应用层客户端功能',
          img: appliLayerClient
        },
        {
          title: '配套服务团队',
          img: supportServiceTeam
        },
        {
          title: '硬件展示',
          img: hardwareDisplay
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(module-header) {
  margin-top: 51px;
  width: 236%;
  @include media-mobile {
    display: none;
  }
  @include media-between(1200px,1500px) {
    width: 256%;
  }
  @include e(name) {
    font-size: 37px;
    color: #5e5e5e;
    margin-bottom: 13px;
    @include media-mobile{
      font-size: 23px;
    }
  }
  @include e(desc) {
    color: #c7c7c7;
    font-size: 20px;
    @include media-mobile{
      font-size: 16px;
    }
  }
}
.about-container {
  padding: 0 220px;
  @include media-mobile {
    padding: 0 20px;
  }
  @include media-between(1200px,1500px) {
    padding: 0 60px;
  }
}
.nd-container {

}
@include b(about) {
  // padding: 0 140px;

  @include e(nd-title) {
    font-size: 34px;
    margin-top: 116px;
    @include media-mobile {
      font-size: 20px;
      text-align: center;
      margin-top: 56px;
    }
  }

  @include e(nd-intro) {
    line-height: 42px;
    margin-top: 51px;
    text-indent: 2em;
    @include media-mobile {
      line-height: 24px;
      margin-top: 24px;
    }
  }

  .about-internet-container {
    
  }

  .bottom-container {
    height: 400px;
    background: #efefef;
    border-radius: 3px;
    padding-left: 34px;
    padding: 8px 14px;
    @include media-mobile {
      display: block;
      height: auto;
    }
  }

  .left-container {
    width: 35%;
    @include media-mobile {
      width: 100%;
    }
  }

  @include e(internet-title) {
    font-size: 28px;
    margin: 200px 0 50px 0;
    @include media-mobile {
      margin: 50px 0 25px 0;
      font-size: 17px;
    }
  }

  @include e(smart-park) {
    padding: 20px 0;
    font-size: 24px;
    border-bottom: 1px solid #c7c7c7;
    @include media-mobile {
      font-size: 16px;
    }
  }

  @include e(internet-intro) {
    margin-top: 27px;
    color: #5E5E5E;
    line-height: 37px;
    @include media-mobile {
      margin-top: 14px;
    }
  }

  @include e(internet-img) {
    width: 56%;
    @include media-mobile {
      width: 100%;
      margin: 10px 0 15px 0;
    }
  }

}
</style>