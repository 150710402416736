<template>
  <div class="company-item" @click="onDetail('item')">
    <div>
      <img :src="data.caseBgImage" alt="" class="company-item__img animateBounce pointer">
    </div>

    <div class="bottom-container">
      <h2 class="company-item__name">{{ data.cooperativeName}}</h2>
      <div class="company-item__date">发布日期: {{ data.createTime}}</div>
      <p class="company-item__intro" v-html="data.cooperativeIntroduction">
      </p>

      <div class="company-item__detail company-item__detail--hidden pointer" @click="onDetail()">查看详情</div>
    </div>
  </div>
</template>

<script>
import { getWindowWidth } from '@/utils/index'
export default {
  name: 'CompanyItem',
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  created() {
  },
  methods: {
    onDetail(type = '') {
      this.common(type)
      window.addEventListener("resize", () => {
        this.common(type)
      })
    },

    common(type) {
      if(getWindowWidth() > 1056 && type === 'item') return
        this.$router.push({
        name: 'excellentCaseDetail',
        params: {
          cooperativeName: this.data.cooperativeName,
          caseIntroduction: this.data.caseIntroduction
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(company-item) {
  width: 32%;
  margin-bottom: 40px;
  background: #F5F5F5;
  @include media-computer {
      &:nth-child(1) {
      width: 100%;
      display: flex;
      background: $--color-white;
      margin-top: 190px;
      & > div:nth-child(1) {
        width: 50%;
      }

      & > div:nth-child(2) {
        width: 50%;
        position: relative;
        margin-left: 60px
      }

      .company-item__name {
        margin: 0;
        font-size: 32px;
      }

      .company-item__date {
        margin: 38px 0 24px 0;
      }

      .company-item__detail {
        position: absolute;
        bottom: 0;
        margin: 0;
      }
    }
  }
  .bottom-container {
    padding: 0 18px 0 20px;
    @include media-mobile {
      padding: 0px 10px 0px 10px;
    }
  }
  // &:nth-child(even) {
  //   margin-left: 24px;
  // }
  @include media-mobile {
    width: 48%;
    margin-bottom: 12px;
    padding-bottom: 10px;
  }
  @include e(img) {
    width: 100%;
    object-fit: cover;

    &:hover.animateBounce  {
      animation-name: myBounce;
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
      animation-direction: alternate;
    }

    @keyframes myBounce {
      100% {
        transform: scale(1.1);
      }
    }

    @include media-sm-mobile {
      height: 141px;
    }
  }

  @include e(name) {
    font-size: 24px;
    margin: 8% 0 10% 0;
    @include media-sm-mobile {
      font-size: 18px;
    }
  }

  @include e(date) {
    margin-bottom: 24px;
    color: #9e9e9e;
    @include media-sm-mobile {
      margin-bottom: 12px;
    }
  }

  @include e(intro) {
    line-height: 30px;
    font-size: 14px;
    height: 156px;
    overflow: hidden;
    text-align: justify;
    @include utils-mutiple-ellipsis(5);
    @include media-mobile {
      line-height: 20px;
      height: 103px;
      // @include utils-mutiple-ellipsis(4);
    }

    @include media-sm-mobile {
      line-height: 16px;
      height: 81px;
    }
    
  }

  @include e(detail) {
    margin-top: 40px;
    width: 112px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #a2a2a2;
    line-height: 40px;
    text-align: center;
    color: #afafaf;
    font-size: 14px;
    margin-top: 5%;
    margin-bottom: 20px;
    transition: .7s;

    &:hover {
      background: $--color-primary;
      border: none;
      color: $--color-white;
    }

    @include m(hidden) {
      @include media-sm-mobile {
        display: none;
      }
    }
  }
}
</style>