<template>
  <div class="more flex flex-center pointer">
    <div>
      <span class="more__text">more</span>
      <VIcon name="icon-shuangjiantouyou" size="18" class="double-arrow"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'More'
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@include b(more) {
  font-size: 24px;
  color: $--color-link;
  .double-arrow {
    position: relative;
    left: -2px;
  }
}
</style>