<template>
  <div class="recruitment">
    <!-- 招贤纳士 · 等你来 -->
    <Banner :bannerImgUrl="bannerImgUrl" :bannerMobile="quickContactMobile" title="优秀产品 · 资产管理"/>
    <h1 class="text-center" style="margin:22px 0;color:red;">支持产品个性化定制，诚邀加盟代理</h1>
    <div class="flex items-middle space-around">
      <div class="box">
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset7.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">RFID货架</div>
              <div>KE-RFEA100</div>
            </div>
          </div>
          <div class="price">￥25200.00</div>
        </div>
        <div class="describe">技术参数：2000*2000*600mm（L*H*W）；
        颜色（白色），层数（4层）内置RFID读写装置，带RFID盘点，亮灯指引<br>
        该产品三层设计，可无限级联拼接多个货架使用，每层货架上方均安装特别设计的RFID天线，并配合RFID读写器使货架上的电子标签处于实时被读取的状态，可24小时在线盘点，并能随时查询所有物品出入库及在库情况，实现库存管理智能化，提高工作效率。柜子右上角分别悬挂摄像头，分别监控柜子取货点，仓库进门点，监控视频历史文件保存2天！<br>
        1.0、货架信息：材质(冷轧钢)，厚度0.35<br>
        1.1、中控大脑：CPU 1.5Ghz 四核，内存4G，32G盘符 1.2、摄像头：成像器件(1/4英寸 Progressive Scan CMOS),有效像素（100万）,分辨率（1280×720）<br>
        1.3、设备通讯协议：webSocket交互；<br>
        1.4、报警扬声器，出入柜成功提示，资产异常报警提示音
        </div>
      </div>
      <div class="box">
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset5.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">RFID手持标签打印机</div>
              <div>KE-BW2663</div>
            </div>
          </div>
          <div class="price">￥14400.00</div>
        </div>
        <div class="describe">技术参数：打印方式 热转印 分辨率 203 dpi; 最大打印速度(20mm/秒)<br>
          RFID打印功能: 集成高性能超高频RDIF读写器/编码器，标签卷 宽度: 最大(50mm)，最小(18 mm);碳带
          卷: 50mm*30米内外碳均可内置印条码 Code 39.Code 93.Code 128/subset A,B.CCodabar.<br>
          Interleave 2 of 5.UPC AE 2 and 5 add-on.<br>
          EAN-13/8/128.UCC-128等一维条码，MaxiCode. PDF417，Data MatrixOR等二维条码<br>
          设备接口: 蓝牙，USB，设备重量: 0.63kg<br>
          机身尺寸: 214mm (长) *98mm (宽)*85mm (高)
        </div>
      </div>
    </div>
    <div class="flex items-middle space-around">
      <div class="box">
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset1.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">RFID手持蓝牙机</div>
              <div>KE-RFID5200</div>
            </div>
          </div>
          <div class="price">￥2560.00</div>
        </div>
        <div class="describe">技术参数：颜色分类: 黑色，通讯方式: 蓝牙+USB2.0读取<br>
          扫描距离: 0-2米，电池容量: 可充电内置钾电池5000mAh， 工作时长>10小时；<br>
          RFID群读最大速率: 每秒50次，产品尺寸: 直径91mm* 厚度22mm
        </div>
      </div>
      <div class="box">
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset3.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">超高频RFID标签</div>
              <div>KM4Q标签</div>
            </div>
          </div>
          <div class="price">￥4.00</div>
        </div>
        <div class="describe">技术参数：
          办议标准: ISO18000-6C EPCC1G2; 读写距离: 小于等于6米<br>
          芯片容量: EPC 128bit User memory 0bit<br>
          芯片寿命: 可擦写10万次，尺寸大小: 70*45<br>
          标准包装: 500片每卷<br>
          产品材质：铜板纸、PET热敏纸、杜邦纸PP合成纸等
        </div>
      </div>
    </div>
    <div class="flex items-top space-around">
     <div class="box" >
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset6.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">物资管理系统</div>
              <div>COHEN-智能物资管理系统</div>
            </div>
          </div>
          <div class="price">￥128000.00</div>
        </div>
        <div class="describe">
          Web后台管理：<br>
          1、物资分类管理<br>2、组织架构管理<br>3、仓库地址管理<br>4、资产柜管理<br>5、库存管理 (出入库单，盘点)<br><br>
          移动端：<br>
          1、入库 (仓库管理，绑定资产，打印标签，蓝牙验签)<br>
          2、资产柜(放入，取出，实时监控资产状态)<br>
          3、资产盘点(所有分类的库存) ;<br> 4、领用 (出库单，拿货)
        </div>
      </div>
      <div class="box" >
        <div class="head">
          <div class="flex items-middle">
            <img src="@/assets/images/asset4.png" class="img">
            <div class="flex-col space-between" style="height:85px">
              <div class="span">RFID超高频手持读卡器</div>
              <div>COHEN-SC2311</div>
            </div>
          </div>
          <div class="price">￥5200.00</div>
        </div>
        <div class="describe" style="margin-bottom: 5%">技术参数：
          整机尺寸：151 x 132 x 76 mm （仅主体）<br>
          整机重量：438 g<br>
          整机颜色：黑色<br>
          电池容量：5000mAh，可拆卸，
          待机时间 > 70 小时（蓝牙连接状态），
          工作时间2.5 小时左右（400张标签群读），
          充电时间4-5 小时（使用标配电源适配器和数据线<br>
          通讯接口：USB3.0 Type-C<br>
          蜂鸣器：1个，支持<br>
          指示灯：3个，电源电量灯，工作灯，BT连接灯<br>
          产品材质：塑胶<br>
          适配手机尺寸：手机夹子：宽度 68-80 mm，厚度 22 mm以下<br>
          其它：与手机保护壳或背夹有关<br>
          蓝牙：支持蓝牙5.0和蓝牙BLE<br>
          读卡距离：可达 18 米（室外空旷环境，Impinj MR6标签）<br>
          群读速率：可达 900 tags/s<br>
        </div>
      </div>
    </div>
    <div class="recruitment-item-container">
      <RecruitmentItem v-for="(item,index) in recruitData" :key="index" :data="item"/>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import bannerImgUrl from '@/assets/images/quick-contact.png'
import RecruitmentItem from './components/RecruitmentItem'
import quickContactMobile from '@/assets/images/quick-contact-mobile.jpg'

import { recruitmentList } from '@/api/system'
export default {
  name: 'Recruitment',
  components: {
    Banner,
    RecruitmentItem
  },
  data() {
    return {
      bannerImgUrl,
      recruitData: [],
      quickContactMobile
    }
  },
  methods: {
    async getWebList() {
      let { rows } = await recruitmentList()
      this.recruitData = rows

    }
  },
  created() {
    // this.getWebList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(recruitment) {
  @include e(position) {
    margin: 119px 0 54px 0;
    font-size: 32px;
    @include media-mobile {
      font-size: 26px;
    }
  }
  .recruitment-item-container,h2 {
    padding: 0 12%;
    @include media-min-width(1500px) {
      padding: 0 18%;
    }
    @include media-sm-mobile {
      padding: 0 15px;
    }
  }
}
.box {
  width: 35%;
  display: flex;
  padding: 50px 50px 0 50px;
  align-items: baseline;
  flex-direction: column;
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
}
.span {
  display: block;
  font-size: 32px;
  font-weight: bold;
  flex-shrink: 0;
  // margin-bottom: 10px;
}
.price {
  color: red;
  font-size: 28px;
}
.img {
  width: 150px;
  height: 150px;
  margin-right: 15px;
  flex-shrink: 0;
}
.describe {
  color: #999;
  // padding-left: 5%;
  line-height: 20px;
}
</style>