<template>
  <div class="contact-now">
    <span class="contact-now__throng-way bold">
      请通过如下方式联系
    </span>
    <div class="tel-container">
      <span class="contact-now__title bold">电话:</span>
      <span class="contact-now__content">13667376667</span>
    </div>
    <div>
      <span class="contact-now__title bold">邮箱:</span>
      <span class="contact-now__content">pursuit@nd-cohen.com</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactNow',

}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/common/var.scss";
@import "@/assets/theme/mixins/mixins.scss";
@import "@/assets/theme/mixins/utils.scss";
@include b(contact-now) {
  width: 263px;
  height: 115px;
  opacity: 1;
  background: #ffffff;
  border: 1px solid #ff862d;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.16); 
  color: $--color-black;
  padding: 17px 59px 17px 20px;
  border-radius: 10px;
  position: absolute;
  left: -212%;
  top: 4px;
  @include media-mobile {
    width: 215px;
    padding: 17px 8px 17px 12px;
    text-align: left;
    z-index: 100;
    left: -176%;
  }
  @include e(throng-way) {
    font-size: 15px;
  }

  .tel-container {
    margin: 25px 0 12px 0;
  }

  @include e(title) {
    font-size: 14px;
  }

  @include e(content) {
    font-size: 14px;
  }
}
</style>